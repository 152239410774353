// Fonts
$fontL: 'Lato', sans-serif;
$fontProxima: 'ProximaNova', sans-serif;
$fontExo2: 'Exo 2', sans-serif;
@import url('https://fonts.googleapis.com/css?family=Exo+2&display=swap');
// Grid
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1280px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1200px
);

$grid-columns: 12;
$grid-gutter-width: 30px;
